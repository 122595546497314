import Vue from "vue";
import VueRouter from "vue-router";
import authComponent from "@/views/AuthComponent.vue";
import loginComponent from "@/views/partials/loginComponent.vue";
import resgiterComponent from "@/views/partials/resgiterComponent.vue";
import payrollDashboard from "@/views/payrollDashboard.vue";
import $eventHub from "../eventHub.js";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: payrollDashboard,
        children: [
            {
                name: "profile",
                path: "profile",
                component: () => import("@/views/Profile.vue"),
            },
            {
                name: "dashboard",
                path: "dashboard",
                component: () => import("@/views/patials/indexComponent"),
            },
            {
                name: "dashboard",
                path: "",
                redirect: "dashboard",
            },
            {
                path: "p9form",
                component: () => import("@/views/patials/reports/P9FormComponent"),
            },
            {
                path: "p9form/:employee_id",
                component: () => import("@/views/patials/reports/P9FormComponent"),
            },
            {
                path: "departments",
                component: () => import("@/views/patials/depComponent"),
            },
            {
                name: "employees",
                path: "employees",
                component: () => import("@/views/patials/employeesComponent"),
            },
            {
                path: "employee/:id",
                component: () => import("@/views/patials/employeeComponent"),
            },
            {
                name: "settings",
                path: "settings",
                component: () => import("@/views/patials/payrollsettingsComponent.vue"),
            },

            {
                name: "payruns",
                path: "payruns",
                component: () => import("@/views/patials/payrunsComponent"),
            },
            {
                path: "payment/:id",
                component: () => import("@/views/patials/salarypaymentComponent"),
            },
            {
                path: "taxpayment/:id",
                component: () => import("@/views/patials/taxPaymentComponent"),
            },

            {
                path: "tier1Payment/:id",
                component: () => import("@/views/patials/tier1PaymentComponent"),
            },

            {
                path: "tier3payment/:id",
                component: () => import("@/views/patials/tier3Payment"),
            },

            {
                path: "tier2Payment/:id",
                component: () => import("@/views/patials/tier2PaymentComponent"),
            },
            {
                path: "payrun/:id",
                component: () => import("@/views/patials/payrunComponent"),
            },
            {
                path: "payslip/:id",
                component: () => import("@/views/patials/payslipComponent"),
            },
            {
                path: "payslips/:id",
                component: () => import("@/views/patials/payslipsComponent"),
            },

            {
                path: "tier3/:id",
                component: () => import("@/views/patials/reports/Tier3"),
            },

            {
                path: "ssnittier1/:id",
                component: () => import("@/views/patials/tier1Component"),
            },

            {
                path: "nhif/:id",
                component: () => import("@/views/patials/nhifcomponent"),
            },

            {
                path: "ssnittier2/:id",
                component: () => import("@/views/patials/tier2Component"),
            },
            {
                path: "taxreport/:id",
                component: () => import("@/views/patials/taxreportComponent"),
            },
            {
                path: "summarysheet/:id",
                component: () => import("@/views/patials/summarysheetComponent"),
            },
            {
                path: "salariescheckout/:id",
                component: () => import("@/views/patials/salariescheckoutComponent"),
            },
            {
                path: "payadvice/:id",
                component: () => import("@/views/PayAdviceComponent")
            }

        ],
    },
    {
        path: "/auth",
        name: "auth",
        component: authComponent,
        children: [
            {
                path: "",
                component: loginComponent,
                name: "login",
            },
            {
                path: "login",
                component: loginComponent,
                name: "login",
            },
            {
                path: "register",
                component: resgiterComponent,
                name: "register",
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (
        typeof to.matched[to.matched.length - 1]?.components.default === "function"
    ) {
        $eventHub.$emit("asyncComponentLoaded");
        $eventHub.$emit("asyncComponentLoading", to); // Start progress bar
    }
    next();
});

router.beforeResolve((to, from, next) => {
    $eventHub.$emit("asyncComponentLoaded"); // Stop progress bar
    next();
});

export default router;
