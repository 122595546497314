<template>
<span>

  <v-menu
      transition="slide-y-transition"
      max-width="300"
  >
    <template v-slot:activator="{on}">

  <v-btn text rounded v-on="on">
    <avatar-component v-if="!$store.state.user.photo" size="35" :label="label"></avatar-component>
    <v-avatar v-else size="40">
      <v-img :src="$store.state.user.photo"></v-img>
    </v-avatar>
    <span class="trim-text-no-padding">{{
      user.first_name
    }} {{ user.last_name }}</span> <v-icon
      color="grey">mdi-chevron-down</v-icon>
  </v-btn>
    </template>
<v-list>

  <v-list-item>
    <v-list-item-avatar>
      <avatar-component size="45" v-if="!$store.state.user.photo" :label="label"></avatar-component>
      <v-avatar v-else size="40">
      <v-img :src="$store.state.user.photo"></v-img>
    </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>

    <v-list-item-title class="trim-text-no-padding">{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
    <v-list-item-subtitle>
      <span v-if="user.is_admin" class="green--text">Admin <v-icon color="green">mdi-check</v-icon></span>
      <span v-else class="warning--text">User</span>

    </v-list-item-subtitle>

    </v-list-item-content>
  </v-list-item>

  <v-list-item to="/profile">
    <v-list-item-content>
    <v-list-item-title>Profile Settings</v-list-item-title>
    </v-list-item-content>
<v-list-item-action>
  <v-icon small color="grey">mdi-chevron-right</v-icon>
</v-list-item-action>
  </v-list-item>

    <v-list-item @click="showLogoutDialog=true">
    <v-list-item-title class="red--text">Logout</v-list-item-title>
  </v-list-item>

</v-list>
  </v-menu>


  <v-dialog :persistent="progress" v-model="showLogoutDialog" width="400">
    <v-card>
      <v-card-title>Confirm Logout</v-card-title>
      <v-card-text>
        <p class="text-h4 font-weight-light">Do you want to logout of your account? </p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="logout" :loading="progress" block x-large rounded depressed dark>Logout</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</span>

</template>

<script>
import {defineComponent} from 'vue'
import store from "@/store";
import AvatarComponent from "@/components/avatarComponent.vue";

export default defineComponent({
  name: "AccountAvaterComponent",
  components: {AvatarComponent},
  data() {
    return {
      showLogoutDialog: false,
      progress: false

    }
  },
  computed: {
    label() {
      return this.user.first_name + " " + this.user.last_name;
    },
    user() {
      return store.state.user;
    }
  },
  methods: {
    logout() {
      this.progress = true;
      // eslint-disable-next-line no-undef
      axios.get("/auth/logout")
          .then(() => {
            this.progress = false;
            location.reload();
          })

    }
  }
})
</script>

<style scoped>

.trim-text-no-padding {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>